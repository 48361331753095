/* Load polyfill:
 * This file and its dependencies get bundled into the "polyfills" chunk by WebPack and loaded as first scripts.
 * Add loading code of further polyfills in here as needed. */
const reflect_metadata = require('reflect-metadata');
import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

console.assert(reflect_metadata, 'Could not load reflect-metadata: ', reflect_metadata);
console.assert(Symbol, 'Could not load Symbol');
console.assert(Promise, 'Could not load Promise');
console.assert(fetch, 'Could not load fetch');
